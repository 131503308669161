/* eslint-disable */

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Drivers Portal (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { CCard, CCardBody, CCardHeader } from "@coreui/react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { Button, Checkbox, Col, Form, Radio, Row, Space, Spin, Table } from "antd";
import { _get_roles, _update_permission } from "common/axios_services";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import { setCount } from "splices/updateCountSplice";


function Roles_settings() {
    const [form] = Form.useForm();

    const [spinnerLoading, setSpinnerLoading] = useState(false);
    const [presentPage, setPresentPage] = useState("view");
    const [roles, setRoles] = useState([]);
    const [updateCount, setUpdateCount] = useState(0);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectedRole, setSelectedRole] = useState(false);
    const [checkedPages, setCheckedPages] = useState([]);

    const pages = [
        {
            name: "Dashboard",
            slug: "dashboard",
        },
        {
            name: "Users",
            slug: "users",
        },
        {
            name: "Sales",
            slug: "sales",
        },
        {
            name: "Sales Lead Details",
            slug: "slDetails",
        },
        {
            name: "Sales Assistant Details",
            slug: "saDetails",
        },
        {
            name: "Referrals",
            slug: "referrals",
        },
        {
            name: "Referral Detals",
            slug: "referralDetails",
        },
        {
            name: "Drivers",
            slug: "driver",
        },
        {
            name: "Merchants",
            slug: "merchants",
        },
        {
            name: "Admins",
            slug: "admins",
        },
        {
            name: "Vendors",
            slug: "vendors",
        },
        {
            name: "Categories",
            slug: "categories",
        },
        {
            name: "Sub-Categories",
            slug: "sub-categories",
        },
        {
            name: "Products",
            slug: "products",
        },
        {
            name: "Shop-Reports",
            slug: "shop-reports",
        },
        {
            name: "Wise9ja-Reports",
            slug: "wise9ja-reports",
        },
        {
            name: "Wallet-Reports",
            slug: "wallet-reports",
        },
        {
            name: "Loyalty-Reports",
            slug: "loyalty-reports",
        },
        {
            name: "Bills-Reports",
            slug: "bills-reports",
        },
        {
            name: "Konnect-Reports",
            slug: "konnect-reports",
        },
        {
            name: "Shop-Orders",
            slug: "orders",
        },
        {
            name: "Wise9ja Transaction",
            slug: "wise9ja-transaction",
        },
        {
            name: "Paybill Transaction",
            slug: "paybills",
        },
        {
            name: "Accounts Transaction",
            slug: "accounts",
        },
        {
            name: "Payments Transaction",
            slug: "payments",
        },
        {
            name: "Delivery States",
            slug: "states",
        },
        {
            name: "Delivery Cities",
            slug: "cities",
        },
        {
            name: "Delivery Locations",
            slug: "locations",
        },
        {
            name: "Delivery date",
            slug: "delivery_date",
        },
        {
            name: "Delivery fee",
            slug: "delivery_fee",
        },
        {
            name: "Inbound",
            slug: "inbound",
        },
        {
            name: "Outbound",
            slug: "outbound",
        },
        {
            name: "Allocations",
            slug: "allocations",
        },
        {
            name: "Reviews",
            slug: "reviews",
        },
        {
            name: "Driver Request",
            slug: "driverRequest",
        },
        // {
        //     name: "Instore",
        //     slug: "instore",
        // },
        // {
        //     name: "vehicles",
        //     slug: "vehicles",
        // },
        // {
        //     name: "Return Items",
        //     slug: "return-items",
        // },
        // {
        //     name: "Dispute",
        //     slug: "dispute",
        // },
        {
            name: "General Settings",
            slug: "general-settings",
        },
        {
            name: "Van-type Settings",
            slug: "van-type-settings",
        },
        {
            name: "Add Van-type",
            slug: "addVanTypes",
        },
        {
            name: "Edit Van-type",
            slug: "edit-van-types",
        },
        {
            name: "Delete Van-type",
            slug: "deleteVanTypes",
        },
        {
            name: "Edit General Settings",
            slug: "edit-general-settings",
        },
        {
            name: "Rate Settings",
            slug: "rate-settings",
        },
        {
            name: "Role/Permission Settings",
            slug: "roles",
        },
        {
            name: "Wise9ja Settings",
            slug: "wise9ja",
        },
        {
            name: "Merchant Settings",
            slug: "merchant_settings",
        },
        {
            name: "Edit Merchant",
            slug: "edit-merchant",
        },
        {
            name: "Inventory Report",
            slug: "inventoryReport",
        },
        {
            name: "Inventory Recieved",
            slug: "inventoryRecieved",
        },
        {
            name: "Inventory Returned",
            slug: "inventoryReturned",
        },
        {
            name: "Inventory Dispute",
            slug: "inventoryDispute",
        },
        {
            name: "Inventory Pickup",
            slug: "inventoryPickup",
        },
        {
            name: "CMS",
            slug: "cms",
        },
        {
            name: "Edit CMS",
            slug: "editCms",
        },
    ]

    const details = [
        {
            name: "User Details",
            slug: "userDetails",
        },
        {
            name: "Driver Details",
            slug: "driverDetails",
        },
        {
            name: "Edit Categories",
            slug: "editCategory",
        },
        {
            name: "Edit SubCategories",
            slug: "editSubcategory",
        },
        {
            name: "Edit Products",
            slug: "editProduct",
        },
        {
            name: "Edit Wise9ja",
            slug: "editWise9ja",
        },
        {
            name: "Edit Delivery Dates",
            slug: "editDeliveryDate",
        },
        {
            name: "Edit Delivery Fees",
            slug: "editDeliveryFee",
        },
        {
            name: "Order Details",
            slug: "orderDetails",
        },
        {
            name: "Account Transaction Details",
            slug: "accountDetails",
        },
        {
            name: "Wise9ja Report Details",
            slug: "wise9jaReportDetails",
        },
        {
            name: "Wallet Report Details",
            slug: "walletReportDetails",
        },
        {
            name: "Loyalty Report Details",
            slug: "loyaltyReportDetails",
        },
        {
            name: "Bills Report Details",
            slug: "billsReportDetails",
        },
    ]

    const detailsUpdates = [
        {
            name: "Add Category",
            slug: "addCategory",
        },
        {
            name: "Add SubCategory",
            slug: "addSubCategory",
        },
        {
            name: "Add States",
            slug: "addStates",
        },
        {
            name: "Add Cities",
            slug: "addCities",
        },
        {
            name: "Add Locations",
            slug: "addLocations",
        },
        {
            name: "Add Dates",
            slug: "addDates",
        },
        {
            name: "Add Fees",
            slug: "addFees",
        },
        {
            name: "Add Product",
            slug: "addProduct",
        },
        {
            name: "Add Wise9ja",
            slug: "addWise9ja",
        },
        {
            name: "Add Admin",
            slug: "addAdmin",
        },
        {
            name: "Add Driver",
            slug: "addDriver",
        },
        {
            name: "Add Merchant",
            slug: "addMerchant",
        },
        {
            name: "Add Merchant User",
            slug: "addMerchantUser",
        },
        {
            name: "Update Rate Settings",
            slug: "updateRate",
        },
        {
            name: "Delete Category",
            slug: "deleteCategory",
        },
        {
            name: "Delete SubCategory",
            slug: "deleteSubCategory",
        },
        {
            name: "Delete Product",
            slug: "deleteProduct",
        },
        {
            name: "Delete States",
            slug: "deleteStates",
        },
        {
            name: "Delete Cities",
            slug: "deleteCities",
        },
        {
            name: "Delete Locations",
            slug: "deleteLocations",
        },
        {
            name: "Delete Dates",
            slug: "deleteDates",
        },
        {
            name: "Delete Fees",
            slug: "deleteFees",
        },
        {
            name: "Delete Wise9ja",
            slug: "deleteWise9ja",
        },
    ]

    const openNotificationWithIcon = (type, message) => {
        notification[type]({ placement: 'bottomRight', description: message, });
    };

    const GetRoles = async () => {
        try {
            const fetchAllRoles = await _get_roles()
            setSpinnerLoading(false)
            setRoles(fetchAllRoles.data.data);
        } catch (err) {
            setSpinnerLoading(false)
            if (err.response) {
                if (err.response === "Unauthorized") {
                    window.location.reload()
                    openNotificationWithIcon('error', err.response.data.message);
                } else {
                    openNotificationWithIcon('error', err.response.data.message);
                }
            } else {
                openNotificationWithIcon('error', err.message);
            }
        }
    }

    useEffect(() => {
        GetRoles()
    }, [updateCount])


    useEffect(() => {
        console.log(selectedOptions);
    }, [selectedOptions]);

    const PageAccess = async (checkedValues) => {
        const updatedOptions = pages.filter(option =>
            checkedValues.includes(option.slug)
        );
        setSelectedOptions(updatedOptions);
        setCheckedPages(checkedValues)
        const data = {
            id: selectedRole.id,
            role_access: checkedValues
        }
        try {
            const update_role = await _update_permission({ data })
            setUpdateCount(updateCount + 1)
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    window.location.reload()
                    openNotificationWithIcon('error', err.response.data.message);
                } else {
                    openNotificationWithIcon('error', err.response.data.message);
                }
            } else {
                openNotificationWithIcon('error', err.message);
            }
        }
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info" >
                                <MDTypography variant="h6" color="white">
                                    Roles Settings
                                </MDTypography>
                            </MDBox>
                            <MDBox py={3} px={2}>
                                <Spin tip="Loading..." spinning={spinnerLoading}>
                                    <React.Fragment>
                                        <Row gutter={[16, 16]}>
                                            <Col xs={24} md={10}>
                                                <CCard>
                                                    <CCardBody style={{ padding: '20px' }}>
                                                        <h6>Role</h6>
                                                        <div style={{ marginTop: "20px" }}>
                                                            <Radio.Group>
                                                                {roles.map((role) => (
                                                                    <div key={role.id}>
                                                                        <Radio key={role.id} onChange={() => { setSelectedRole(role); setCheckedPages(role.role_access) }} value={role.id}>
                                                                            {role.role_name}
                                                                        </Radio>
                                                                    </div>
                                                                ))}
                                                            </Radio.Group>
                                                        </div>
                                                    </CCardBody>
                                                </CCard>
                                            </Col>
                                            <Col xs={24} md={14}>
                                                <CCard>
                                                    <CCardBody>
                                                        <h6>Permissions </h6>
                                                        <div style={{ marginTop: "20px" }}>
                                                            <Checkbox.Group value={checkedPages} onChange={PageAccess} style={{ display: "block" }}>
                                                                <Row gutter={[16, 16]}>
                                                                    <Col xs={24} md={7}>
                                                                        <span>Pages</span>
                                                                        {pages.map(option => (
                                                                            <div style={{ marginBottom: "5px" }}>
                                                                                <Checkbox key={option.slug} value={option.slug}>
                                                                                    {option.name}
                                                                                </Checkbox>
                                                                            </div>
                                                                        ))}
                                                                    </Col>
                                                                    <Col xs={24} md={7}>
                                                                        <span>Details</span>
                                                                        {details.map(option => (
                                                                            <div style={{ marginBottom: "5px" }}>
                                                                                <Checkbox key={option.slug} value={option.slug}>
                                                                                    {option.name}
                                                                                </Checkbox>
                                                                            </div>
                                                                        ))}
                                                                    </Col>
                                                                    <Col xs={24} md={7}>
                                                                        <span>Update</span>
                                                                        {detailsUpdates.map(option => (
                                                                            <div style={{ marginBottom: "5px" }}>
                                                                                <Checkbox key={option.slug} value={option.slug}>
                                                                                    {option.name}
                                                                                </Checkbox>
                                                                            </div>
                                                                        ))}
                                                                    </Col>
                                                                </Row>
                                                            </Checkbox.Group>
                                                        </div>
                                                    </CCardBody>
                                                </CCard>
                                            </Col>
                                        </Row>
                                    </React.Fragment>
                                </Spin>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout >
    );
}

export default Roles_settings;