/* eslint-disable */

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Drivers Portal (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { CCard, CCardBody, CCardHeader } from '@coreui/react';
import { Spin, Table, notification } from 'antd';
import Jwt_decrypt from "common/Jwt_decrypt";
import Jwt_encrypt from "common/Jwt_encrypt";
import axiosCall from "common/axios_call";
import { _get_profile } from "common/axios_services";
import { useEffect, useState } from "react";
import SettingModal from "./SettingModal";


function Rate_settings() {

    const [updateRate, setUpdateRate] = useState(false);
    const [rate_settings, setRate_settings] = useState(false);
    const general_setting = [];
    const referral_setting = [];
    const user_setting = [];
    const wisenija_setting = [];
    const transactions_setting = [];
    const shop_setting = [];
    const bonus_setting = [];
    const sales_setting = [];
    const openNotificationWithIcon = (type, message) => {
        notification[type]({ message: '', placement: 'bottomRight', description: message, });
    };

    const refresh_token = () => {
        axiosCall.post('/auth/refresh-token', { token }).then(res => {
            if (res.data.status === 'success') {
                localStorage.setItem('konnect_admin_token', Jwt_encrypt({ token: res.data.data.token }));
                window.location.reload();
            } else {
                openNotificationWithIcon('error', res.data.message)
                // navigate('/login')
            }
        }).catch(err => {
            openNotificationWithIcon('error', err.response.data.message)
            // navigate('/login')
        })
    }

    const token = Jwt_decrypt({ token: localStorage.konnect_admin_token });
    const fetchUser = async () => {
        const user_profile = await _get_profile()
        if (user_profile.data.status == "success") {
            if (user_profile.data.data.role.role_access.includes("updateRate")) {
                setUpdateRate(true)
            } else {
                setUpdateRate(false)
            }
        } else {
            setUpdateRate(false)
        }
    }

    useEffect(() => {
        if (!token) {
            navigate('/')
        }
        axiosCall(`/rate-settings`, {
            headers: { Authorization: `Bearer ${token}` }
        })
            .then(res => {
                setRate_settings(res.data.data)
            })
            .catch(err => {
                if (err.response.data.message === "Unauthorized") {
                    refresh_token()
                } else {
                    openNotificationWithIcon('error', err.response.data.message)
                }
            })
        fetchUser()
    }, [token])

    if (rate_settings) {
        for (const setting of rate_settings) {
            if (setting.service === "General") {
                general_setting.push(setting);
            }
            else if (setting.service === "WiseNija") {
                wisenija_setting.push(setting);
            }
            else if (setting.service === "Sign-up") {
                user_setting.push(setting);
            }
            else if (setting.service === "Referral") {
                referral_setting.push(setting);
            }
            else if (setting.service === "Shop") {
                shop_setting.push(setting);
            }
            else if (setting.service === "Transaction") {
                transactions_setting.push(setting);
            }
            else if (setting.service === "Bonus") {
                bonus_setting.push(setting);
            }
            else if (setting.service === "Sales") {
                sales_setting.push(setting);
            }
        }
    }


    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info" >
                                <MDTypography variant="h6" color="white">
                                    Rate-Settings
                                </MDTypography>
                            </MDBox>
                            <MDBox py={3} px={2}>
                                {/* User commision */}
                                <div style={{ marginTop: "5%" }} />
                                <CCard mt={2}>
                                    <CCardHeader>User Sign-up Bonus</CCardHeader>
                                    <CCardBody>
                                        {user_setting ?
                                            <div style={{ overflowX: "auto", paddingLeft: "5px", paddingRight: "5px" }}>
                                                <table className="table table-bordered">
                                                    <thead>
                                                        <th>Title</th>
                                                        <th>T1 NU</th>
                                                        <th>T1 FV</th>
                                                        <th>T2 NU</th>
                                                        <th>T2 FV</th>
                                                        <th>Unit</th>
                                                        <th>Account Type</th>
                                                        <th>Action</th>
                                                    </thead>
                                                    <tbody>
                                                        {user_setting.map((user_commission, index) => (
                                                            <tr key={index}>
                                                                <td>{user_commission?.title}</td>
                                                                <td>{user_commission?.value}</td>
                                                                <td>{user_commission?.level_b_value}</td>
                                                                <td>{user_commission?.la_app_value}</td>
                                                                <td>{user_commission?.lb_app_value}</td>
                                                                <td>{user_commission?.unit}</td>
                                                                <td>{user_commission?.account_type}</td>
                                                                <td>{updateRate && <SettingModal setting={user_commission} />}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                                {/* <Table columns={columns} pagination={false} dataSource={pending_general} bordered /> */}
                                            </div>
                                            :
                                            <div className="">
                                                <Spin />
                                            </div>
                                        }
                                    </CCardBody>
                                </CCard>
                                {/* Referral */}
                                <div style={{ marginTop: "5%" }} />
                                <CCard mt={2}>
                                    <CCardHeader>Referral Bonuses</CCardHeader>
                                    <CCardBody>
                                        {rate_settings ?
                                            <div>
                                                <table className="table table-bordered">
                                                    <thead>
                                                        <th>Title</th>
                                                        <th>T1 NU</th>
                                                        <th>T1 FV</th>
                                                        <th>T2 NU</th>
                                                        <th>T2 FV</th>
                                                        <th>Unit</th>
                                                        <th>Account Type</th>
                                                        <th>Action</th>
                                                    </thead>
                                                    <tbody>
                                                        {referral_setting.map((referral, index) => (
                                                            <tr key={index}>
                                                                <td>{referral?.title}</td>
                                                                <td>{referral?.value}</td>
                                                                <td>{referral?.level_b_value}</td>
                                                                <td>{referral?.la_app_value}</td>
                                                                <td>{referral?.lb_app_value}</td>
                                                                <td>{referral?.unit}</td>
                                                                <td>{referral?.account_type}</td>
                                                                <td>{updateRate && <SettingModal setting={referral} />}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                                {/* <div style={{ marginTop: "5%" }}></div> */}
                                                {/* <Pagination showSizeChanger current={page} total={total_users} showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} users`} defaultPageSize={page_size} onChange={change_page} /> */}
                                            </div>
                                            :
                                            <div className="">
                                                <Spin />
                                            </div>
                                        }
                                    </CCardBody>
                                </CCard>
                                {/* Referral */}
                                <div style={{ marginTop: "5%" }} />
                                <CCard mt={2}>
                                    <CCardHeader>Shop Settings</CCardHeader>
                                    <CCardBody>
                                        {rate_settings ?
                                            <div>
                                                <table className="table table-bordered">
                                                    <thead>
                                                        <th>Title</th>
                                                        <th>T1 NU</th>
                                                        <th>T1 FV</th>
                                                        <th>T2 NU</th>
                                                        <th>T2 FV</th>
                                                        <th>Unit</th>
                                                        <th>Min Cap</th>
                                                        <th>Max Cap</th>
                                                        <th>Account Type</th>
                                                        <th>Action</th>
                                                    </thead>
                                                    <tbody>
                                                        {shop_setting.map((referral, index) => (
                                                            <tr key={index}>
                                                                <td>{referral?.title}</td>
                                                                <td>{referral?.value}</td>
                                                                <td>{referral?.level_b_value}</td>
                                                                <td>{referral?.la_app_value}</td>
                                                                <td>{referral?.lb_app_value}</td>
                                                                <td>{referral?.unit}</td>
                                                                <td>{referral?.min_cap}</td>
                                                                <td>{referral?.max_cap}</td>
                                                                <td>{referral?.account_type}</td>
                                                                <td>{updateRate && <SettingModal setting={referral} />}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                            :
                                            <div className="">
                                                <Spin />
                                            </div>
                                        }
                                    </CCardBody>
                                </CCard>
                                {/* Bonus */}
                                <div style={{ marginTop: "5%" }} />
                                <CCard mt={2}>
                                    <CCardHeader>Bonus Account</CCardHeader>
                                    <CCardBody>
                                        {rate_settings ?
                                            <div>
                                                <table className="table table-bordered">
                                                    <thead>
                                                        <th>Title</th>
                                                        <th>T1 NU</th>
                                                        <th>T1 FV</th>
                                                        <th>T2 NU</th>
                                                        <th>T2 FV</th>
                                                        <th>Unit</th>
                                                        <th>Min Cap</th>
                                                        <th>Max Cap</th>
                                                        <th>Account Type</th>
                                                        <th>Action</th>
                                                    </thead>
                                                    <tbody>
                                                        {bonus_setting.map((delivery, index) => (
                                                            <tr key={index}>
                                                                <td>{delivery.title}</td>
                                                                <td>{delivery.value}</td>
                                                                <td>{delivery.level_b_value}</td>
                                                                <td>{delivery?.la_app_value}</td>
                                                                <td>{delivery?.lb_app_value}</td>
                                                                <td>{delivery.unit}</td>
                                                                <td>{delivery.min_cap}</td>
                                                                <td>{delivery.max_cap}</td>
                                                                <td>{delivery?.account_type}</td>
                                                                <td>{updateRate && <SettingModal setting={delivery} />}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                            :
                                            <div className="">
                                                <Spin />
                                            </div>
                                        }
                                    </CCardBody>
                                </CCard>
                                <div style={{ marginTop: "5%" }} />
                                <CCard mt={2}>
                                    <CCardHeader>Sales Settings</CCardHeader>
                                    <CCardBody>
                                        {rate_settings ?
                                            <div>
                                                <table className="table table-bordered">
                                                    <thead>
                                                        <th>Title</th>
                                                        <th>T1 NU</th>
                                                        <th>T1 FV</th>
                                                        <th>T2 NU</th>
                                                        <th>T2 FV</th>
                                                        <th>Unit</th>
                                                        <th>Min Cap</th>
                                                        <th>Max Cap</th>
                                                        <th>Account Type</th>
                                                        <th>Action</th>
                                                    </thead>
                                                    <tbody>
                                                        {sales_setting.map((sales, index) => (
                                                            <tr key={index}>
                                                                <td>{sales.title}</td>
                                                                <td>{sales.value}</td>
                                                                <td>{sales.level_b_value}</td>
                                                                <td>{sales?.la_app_value}</td>
                                                                <td>{sales?.lb_app_value}</td>
                                                                <td>{sales.unit}</td>
                                                                <td>{sales.min_cap}</td>
                                                                <td>{sales.max_cap}</td>
                                                                <td>{sales?.account_type}</td>
                                                                <td>{updateRate && <SettingModal setting={sales} />}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                            :
                                            <div className="">
                                                <Spin />
                                            </div>
                                        }
                                    </CCardBody>
                                </CCard>
                                {/* Transfer */}
                                <div style={{ marginTop: "5%" }} />
                                <CCard mt={2}>
                                    <CCardHeader>Transaction Setting</CCardHeader>
                                    <CCardBody>
                                        {rate_settings ?
                                            <div>
                                                <table className="table table-bordered">
                                                    <thead>
                                                        <th>Title</th>
                                                        <th>T1 NU</th>
                                                        <th>T1 FV</th>
                                                        <th>T2 NU</th>
                                                        <th>T2 FV</th>
                                                        <th>Unit</th>
                                                        <th>Min Cap</th>
                                                        <th>Max Cap</th>
                                                        <th>Account Type</th>
                                                        <th>Action</th>
                                                    </thead>
                                                    <tbody>
                                                        {transactions_setting.map((delivery, index) => (
                                                            <tr key={index}>
                                                                <td>{delivery.title}</td>
                                                                <td>{delivery.value}</td>
                                                                <td>{delivery.level_b_value}</td>
                                                                <td>{delivery?.la_app_value}</td>
                                                                <td>{delivery?.lb_app_value}</td>
                                                                <td>{delivery.unit}</td>
                                                                <td>{delivery.min_cap}</td>
                                                                <td>{delivery.max_cap}</td>
                                                                <td>{delivery?.account_type}</td>
                                                                <td>{updateRate && <SettingModal setting={delivery} />}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                            :
                                            <div className="">
                                                <Spin />
                                            </div>
                                        }
                                    </CCardBody>
                                </CCard>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default Rate_settings;
