import axiosCall from "./axios_call"

const { default: Jwt_decrypt } = require("./Jwt_decrypt")


const _get_profile = async () => {
    const jwt_token = localStorage.konnect_admin_token
    const appRole = "admin"
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.get(`/auth/profile?app=${appRole}`, { headers: { "Authorization": `Bearer ${token}` } })
    } else {
        return axiosCall.get(`/auth/profile?app=${appRole}`, { headers: { "Authorization": "Bearer" + "" } })
    }
}

export const _get_users = async ({ page, page_size, query, category, verified, filterDate, filterToDate, tier }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        if (!verified && verified !== 0) {
            return axiosCall(`/admin/user?tier=${tier}&page=${page}&per_page=${page_size}&search=${query}&category=${category}&from=${filterDate}&to=${filterToDate}`, { headers: { "Authorization": "Bearer " + token } })
        }
        return axiosCall(`/admin/user?tier=${tier}&page=${page}&per_page=${page_size}&search=${query}&category=${category}&verified=${verified}&from=${filterDate}&to=${filterToDate}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/admin/user?tier=${tier}&page=${page}&per_page=${page_size}&search=${query}&category=${category}&verified=${verified}&from=${filterDate}&to=${filterToDate}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}
export const _delete_user = async ({ id }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.delete(`/admin/user/${id}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.delete(`/admin/user/${id}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}
export const _delete_users = async ({ selectedRows }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.post(`/admin/user/multiple_delete`, { users: selectedRows }, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.post(`/admin/user/multiple_delete`, { users: selectedRows }, { headers: { "Authorization": "Bearer " + "" } })
    }
}
export const _resend_users = async ({ selectedRows }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.post(`/admin/user/multiple_resend_verification_email`, { users: selectedRows }, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.post(`/admin/user/multiple_resend_verification_email`, { users: selectedRows }, { headers: { "Authorization": "Bearer " + "" } })
    }
}
export const _send_bulk_sms = async ({ message, user_ids }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.post(`/admin/user/send_bulk_sms`, { message, user_ids }, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.post(`/admin/user/send_bulk_sms`, { message, user_ids }, { headers: { "Authorization": "Bearer " + "" } })
    }
}
export const _resend_verify_user = async ({ id }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/auth/resend_email/${id}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/auth/resend_email/${id}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

export const _get_users_metrix = async ({ filterDate, filterToDate }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/admin/user/users_metrix?from=${filterDate}&to=${filterToDate}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/admin/user/users_metrix?from=${filterDate}&to=${filterToDate}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

export const _export_sales = async ({ search, category }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/sales-reps/admin/user_list/export?search=${search}&category=${category}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/sales-reps/admin/user_list/export?search=${search}&category=${category}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

export const _export_sales_sa = async ({ id }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/sales-reps/admin/user/so/export/${id}?search=&category=sa&from=&to=`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/sales-reps/admin/user/so/export/${id}?search=&category=sa&from=&to=`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

export const _get_sales = async ({ page, page_size, search, category, filterDate, filterToDate }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/sales-reps/admin/user_list?page=${page}&per_page=${page_size}&search=${search}&category=${category}&from=${filterDate}&to=${filterToDate}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/sales-reps/admin/user_list?page=${page}&per_page=${page_size}&search=${search}&category=${category}&from=${filterDate}&to=${filterToDate}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}
export const _get_referral_metrix = async ({ filterDate, filterToDate }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/referral/metrix?from=${filterDate}&to=${filterToDate}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/referral/metrix?from=${filterDate}&to=${filterToDate}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

export const _get_inventory_returned = async ({ page, search, page_size, filterDate, filterToDate, item_id, status }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/inventory/return?page=${page}&per_page=${page_size}&search=${search}&item_id=${item_id}&status=${status}&date=${filterDate}&from=${filterDate}&to=${filterToDate}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/inventory/return?page=${page}&per_page=${page_size}&search=${search}&item_id=${item_id}&status=${status}&date=${filterDate}&from=${filterDate}&to=${filterToDate}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}
export const _get_inventory_report = async ({ filterDate, filterToDate }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/inventory/report?date=${filterDate}&from=${filterDate}&to=${filterToDate}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/inventory/report?date=${filterDate}&from=${filterDate}&to=${filterToDate}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}
export const _get_inventory_dispute = async ({ page, search, page_size, filterDate, filterToDate, item_id, status }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/inventory/disputes?page=${page}&per_page=${page_size}&search=${search}&item_id=${item_id}&status=${status}&date=${filterDate}&from=${filterDate}&to=${filterToDate}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/inventory/disputes?page=${page}&per_page=${page_size}&search=${search}&item_id=${item_id}&status=${status}&date=${filterDate}&from=${filterDate}&to=${filterToDate}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}
export const _account_verification = async ({ ref }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.get(`/admin/user/ref_code/${ref}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.get(`/admin/user/ref_code/${ref}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}
export const _create_dispute = async ({ data }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.post(`/inventory/disputes`, data, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.post(`/inventory/disputes`, data, { headers: { "Authorization": "Bearer " + "" } })
    }
}
export const _get_inventory_recieved = async ({ page, search, page_size, filterDate, filterToDate, item_id, status }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/inventory/receives?page=${page}&per_page=${page_size}&search=${search}&item_id=${item_id}&status=${status}&date=${filterDate}&from=${filterDate}&to=${filterToDate}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/inventory/receives?page=${page}&per_page=${page_size}&search=${search}&item_id=${item_id}&status=${status}&date=${filterDate}&from=${filterDate}&to=${filterToDate}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}
export const _create_inventory_received = async ({ data }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.post(`/inventory/receives`, data, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.post(`/inventory/receives`, data, { headers: { "Authorization": "Bearer " + "" } })
    }
}
export const _edit_inventory_received = async ({ id, data }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.patch(`/inventory/receives/${id}`, data, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.patch(`/inventory/receives/${id}`, data, { headers: { "Authorization": "Bearer " + "" } })
    }
}
export const _get_all_products = async ({ page, per_page, search }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/products/no_pagination?page=${page}&per_page=${per_page}&search=${search}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/products/no_pagination?page=${page}&per_page=${per_page}&search=${search}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}
export const _get_general_settings = async () => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/general-settings`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/general-settings`, { headers: { "Authorization": "Bearer " + "" } })
    }
}
export const _get_cms = async ({ page, type, name }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/cms?page=${page}&type=${type}&name=${name}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/cms?page=${page}&type=${type}&name=${name}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

export const _cms_by_name = async ({ name }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/cms/name/${name}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/cms/name/${name}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}
export const _general_settings_by_id = async ({ id }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/general-settings/${id}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/general-settings/${id}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}
export const _van_type_by_id = async ({ id }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/van-type/${id}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/van-type/${id}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}
export const _merchant_by_id = async ({ id }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/merchants/${id}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/merchants/${id}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}
export const _add_cms = async (data) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.post(`/cms`, data, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.post(`/cms`, data, { headers: { "Authorization": "Bearer " + "" } })
    }
}

export const _update_merchant_settings = async ({ data, id }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.patch(`/merchants/${id}`, data, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.patch(`/merchants/${id}`, data, { headers: { "Authorization": "Bearer " + "" } })
    }
}
export const _update_van_type = async ({ data, id }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.patch(`/van-type/${id}`, data, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.patch(`/van-type/${id}`, data, { headers: { "Authorization": "Bearer " + "" } })
    }
}
export const _update_general_settings = async ({ data, id }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.patch(`/general-settings/${id}/update`, data, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.patch(`/general-settings/${id}/update`, data, { headers: { "Authorization": "Bearer " + "" } })
    }
}
export const _update_cms = async ({ data, id }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.patch(`/cms/${id}`, data, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.patch(`/cms/${id}`, data, { headers: { "Authorization": "Bearer " + "" } })
    }
}

export const _get_sales_sa_details = async ({ page, page_size, search, category, user_id, filterDate, filterToDate }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/sales-reps/admin/user/so/${user_id}?page=${page}&per_page=${page_size}&search=${search}&category=${category}&from=${filterDate}&to=${filterToDate}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/sales-reps/admin/user/so/${user_id}?page=${page}&per_page=${page_size}&search=${search}&category=${category}&from=${filterDate}&to=${filterToDate}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

export const _get_sales_details = async ({ page, page_size, search, category, user_id, filterDate, filterToDate }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/sales-reps/admin/sl/get_sa/${user_id}?page=${page}&per_page=${page_size}&search=${search}&category=${category}&from=${filterDate}&to=${filterToDate}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/sales-reps/admin/sl/get_sa/${user_id}?page=${page}&per_page=${page_size}&search=${search}&category=${category}&from=${filterDate}&to=${filterToDate}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

export const _get_attachable_sa = async ({ page, page_size, search }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/referral/sa_users?page=${page}&per_page=${page_size}&search=${search}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/referral/sa_users?page=${page}&per_page=${page_size}&search=${search}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

export const _get_attachable_so = async ({ page, page_size, search }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/referral/so_users?page=${page}&per_page=${page_size}&search=${search}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/referral/so_users?page=${page}&per_page=${page_size}&search=${search}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _get_admin_matrix = async () => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.get(`/admin-dashboard/users_metrix`, { headers: { "Authorization": `Bearer ${token}` } })
    } else {
        return axiosCall.get(`/admin-dashboard/users_metrix`, { headers: { "Authorization": "Bearer" + "" } })
    }
}

const _get_shop_matrix = async () => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.get(`/admin-dashboard/shop_orders_metrix`, { headers: { "Authorization": `Bearer ${token}` } })
    } else {
        return axiosCall.get(`/admin-dashboard/shop_orders_metrix`, { headers: { "Authorization": "Bearer" + "" } })
    }
}

const _add_state = async (data) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.post(`/states`, data, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.post(`/states`, data, { headers: { "Authorization": "Bearer " + "" } })
    }
}

export const _attach_sa = async ({ ref_id, status, user_id }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.patch(`/admin/user/attach_sa/${ref_id}`, { sl_user_id: user_id, status }, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.patch(`/admin/user/attach_sa/${ref_id}`, { sl_user_id: user_id, status }, { headers: { "Authorization": "Bearer " + "" } })
    }
}
export const _attach_so = async ({ ref_id, status, user_id }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.post(`/admin/user/attach_so`, { sa_user_id: user_id, user_id: ref_id, status }, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.post(`/admin/user/attach_so`, { sa_user_id: user_id, user_id: ref_id, status }, { headers: { "Authorization": "Bearer " + "" } })
    }
}
export const _upgrade_sl = async ({ id, status }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.patch(`/admin/user/activate_sl/${id}`, { status }, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.patch(`/admin/user/activate_sl/${id}`, { status }, { headers: { "Authorization": "Bearer " + "" } })
    }
}
export const _upgrade_sa = async ({ id, status }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.patch(`/admin/user/activate_sa/${id}`, { status }, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.patch(`/admin/user/activate_sa/${id}`, { status }, { headers: { "Authorization": "Bearer " + "" } })
    }
}
export const _get_sales_matrix = async ({ filterDate, filterToDate }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/sales-reps/admin/metrix?from=${filterDate}&to=${filterToDate}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/sales-reps/admin/metrix?from=${filterDate}&to=${filterToDate}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}
export const _get_wallet_matrix = async () => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/wallet-history/wallet/total_monthly_report`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/wallet-history/wallet/total_monthly_report`, { headers: { "Authorization": "Bearer " + "" } })
    }
}
export const _get_wise9ja_matrix = async () => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/wisenija-subs/total_monthly_report`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/wisenija-subs/total_monthly_report`, { headers: { "Authorization": "Bearer " + "" } })
    }
}
export const _get_yearly_shop_matrix = async () => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/orders/total_yearly_report`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/orders/total_yearly_report`, { headers: { "Authorization": "Bearer " + "" } })
    }
}
export const _get_order_monthly_report = async ({ month, page, page_size, paymentOpt, status, search }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/orders/monthly_report/${month}?page=${page}&per_page=${page_size}&status=${status}&search=${search}&payment_opt=${paymentOpt}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/orders/monthly_report/${month}?page=${page}&per_page=${page_size}&status=${status}&search=${search}&payment_opt=${paymentOpt}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}
export const _get_order_daily_report = async ({ date, page, page_size, paymentOpt, status, search }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/orders/daily_report/${date}?page=${page}&per_page=${page_size}&status=${status}&search=${search}&payment_opt=${paymentOpt}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/orders/daily_report/${date}?page=${page}&per_page=${page_size}&status=${status}&search=${search}&payment_opt=${paymentOpt}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}
export const _get_daily_shop_matrix = async ({ date }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/orders/total_dayly_report/${date}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/orders/total_dayly_report/${date}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}
export const _get_monthly_shop_matrix = async ({ month }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/orders/total_monthly_report/${month}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/orders/total_monthly_report/${month}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _edit_wise_status = async ({ id, status }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.patch(`/wisenija_plan/display_status/${id}`, { status }, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.patch(`/wisenija_plan/display_status/${id}`, { status }, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _edit_state_status = async ({ id, status }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.patch(`/states/${id}/status`, { status }, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.patch(`/states/${id}/status`, { status }, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _edit_city_status = async ({ id, status }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.patch(`/cities/${id}/status`, { status }, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.patch(`/cities/${id}/status`, { status }, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _edit_location_status = async ({ id, status }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.patch(`/locations/${id}/status`, { status }, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.patch(`/locations/${id}/status`, { status }, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _get_state = async ({ page, page_size }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/states?page=${page}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/states?page=${page}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _get_wise9ja = async ({ page, page_size }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/wisenija-subs?page=${page}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/wisenija-subs?page=${page}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

export const _get_order_yearly_report = async () => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/orders/yearly_report`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/orders/yearly_report`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _get_wise9ja_report = async () => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/wisenija-subs/monthly_report`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/wisenija-subs/monthly_report`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _get_wallet_report = async () => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/wallet-history/wallet/monthly_report`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/wallet-history/wallet/monthly_report`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _get_loyalty_report = async () => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/wallet-history/loyalty_account/monthly_report`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/wallet-history/loyalty_account/monthly_report`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _get_bills_report = async () => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/wallet-history/bills/monthly_report`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/wallet-history/bills/monthly_report`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _get_wise9ja_month = async ({ page, page_size, search, month }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/wisenija-subs/monthly_report/${month}?page=${page}&per_page=${page_size}&search=${search}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/wisenija-subs/monthly_report/${month}?page=${page}&per_page=${page_size}&search=${search}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _get_wallet_month = async ({ page, page_size, search, month }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/wallet-history/wallet/monthly_report/${month}?page=${page}&per_page=${page_size}&search=${search}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/wallet-history/wallet/monthly_report/${month}?page=${page}&per_page=${page_size}&search=${search}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _get_orders = async ({ page, page_size, query, filterDate, filterToDate, selectedDeliveryOption, selectedPaymentOption }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/orders?page=${page}&per_page=${page_size}&query=${query}&from=${filterDate}&to=${filterToDate}&delivery_opt${selectedDeliveryOption}&payment_opt=${selectedPaymentOption}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/orders?page=${page}&per_page=${page_size}&query=${query}&from=${filterDate}&to=${filterToDate}&delivery_opt${selectedDeliveryOption}&payment_opt=${selectedPaymentOption}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _get_lga = async ({ page, page_size }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/lga?page=${page}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/lga?page=${page}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _get_each_order = async ({ id }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/orders/transaction_ref/${id}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/orders/transaction_ref/${id}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}
const _get_orderby_dateid = async ({ id, lga, page, page_size }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/orders/delivery_date/${id}?page=${page}&per_page=${page_size}&lga=${lga}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/orders/delivery_date/${id}?page=${page}&per_page=${page_size}&lga=${lga}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}
const _wise9ja_plan = async ({ page, page_size }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/wisenija_plan?page=${page}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/wisenija_plan?page=${page}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _get_allocation_orders = async ({ day, lga, page, page_size }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/allocations/all_percel?page=${page}&per_page=${page_size}&lga=${lga}&today_day=${day}&search=`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/allocations/all_percel?page=${page}&per_page=${page_size}&lga=${lga}&today_day=${day}&search=`, { headers: { "Authorization": "Bearer " + "" } })
    }
}


const _delete_state = async ({ id }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.delete(`/states/${id}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.delete(`/states/${id}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _get_all_state = async () => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/states/no_pagination`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/states/no_pagination`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

export const _get_all_referrals = async ({ page, page_size, filterDate, filterToDate }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/user-profile/referral?page=${page}&per_page=${page_size}&from=${filterDate}&to=${filterToDate}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/user-profile/referral?page=${page}&per_page=${page_size}&from=${filterDate}&to=${filterToDate}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

export const _get_all_referred = async ({ id, page, page_size }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/referral/user/${id}?page=${page}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/referral/user/${id}?page=${page}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _get_all_payments = async ({ page, page_size }) => {
    const searchPar = ""
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/wallet-history?page=${page}&per_page=${page_size}&search=${searchPar}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/wallet-history?page=${page}&per_page=${page_size}&search=${searchPar}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _get_user_payments = async ({ user_id, page, page_size }) => {
    const searchPar = ""
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/wallet-history/user/${user_id}?page=${page}&per_page=${page_size}&search=${searchPar}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/wallet-history/user/${user_id}?page=${page}&per_page=${page_size}&search=${searchPar}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}


const _get_all_wallet = async ({ page, page_size, query }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/account?page=${page}&per_page=${page_size}&query=${query}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/account?page=${page}&per_page=${page_size}&query=${query}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _add_city = async (data) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.post(`/cities`, data, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.post(`/cities`, data, { headers: { "Authorization": "Bearer " + "" } })
    }
}


const _get_city = async ({ page, page_size }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/cities?page=${page}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/cities?page=${page}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _delete_city = async ({ id }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.delete(`/cities/${id}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.delete(`/cities/${id}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _get_all_cities = async () => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/cities/no_pagination`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/cities/no_pagination`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _add_location = async (data) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.post(`/locations`, data, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.post(`/locations`, data, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _get_locations = async ({ page, page_size }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/locations?page=${page}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/locations?page=${page}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _delete_location = async ({ id }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.delete(`/locations/${id}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.delete(`/locations/${id}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _add_date = async (data) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.post(`/delivery-date`, data, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.post(`/delivery-date`, data, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _get_dates = async ({ page, page_size }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/delivery-date?page=${page}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/delivery-date?page=${page}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _add_delivery_fee = async (data) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.post(`/delivery-fees`, data, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.post(`/delivery-fees`, data, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _get_delivery_fees = async ({ page, page_size }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/delivery-fees?page=${page}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/delivery-fees?page=${page}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _delete_delivery_fee = async ({ id }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.delete(`/delivery-fees/${id}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.delete(`/delivery-fees/${id}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _fee_id = async ({ id }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/delivery-fees/${id}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/delivery-fees/${id}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _edit_fee = async ({ data, id }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.patch(`/delivery-fees/${id}`, data, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.patch(`/delivery-fees/${id}`, data, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _delete_date = async ({ id }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.delete(`/delivery-date/${id}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.delete(`/delivery-date/${id}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _date_id = async ({ id }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/delivery-date/${id}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/delivery-date/${id}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _edit_date = async ({ data, id }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.patch(`/delivery-date/update_date/${id}`, data, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.patch(`/delivery-date/update_date/${id}`, data, { headers: { "Authorization": "Bearer " + "" } })
    }
}
export const _edit_date_options = async ({ data, id }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.patch(`/delivery-date/${id}`, data, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.patch(`/delivery-date/${id}`, data, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _get_roles = async () => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/role`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/role`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _get_roles_byusertype = async ({ type }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/role/${type}/user-type`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/role/${type}/user-type`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _get_merchants_users = async ({ page, page_size, search, category, merchant_id, from, to }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/mertant-profile?page=${page}&per_page=${page_size}&search=${search}&category=${category}&merchant_id=${merchant_id}&from=${from}&to=${to}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/mertant-profile?page=${page}&per_page=${page_size}&search=${search}&category=${category}&merchant_id=${merchant_id}&from=${from}&to=${to}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _get_merchants_settings = async ({ page, page_size, search, category }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/merchants?page=${page}&per_page=${page_size}&search=${search}&category=${category}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/merchants?page=${page}&per_page=${page_size}&search=${search}&category=${category}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _add_merchants_settings = async (data) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.post(`/merchants`, data, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.post(`/merchants`, data, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _add_merchants_user = async (data) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.post(`/mertant-profile`, data, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.post(`/mertant-profile`, data, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _update_permission = async ({ data }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.patch(`/role/${data.id}/parmission`, { role_access: data.role_access }, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.patch(`/role/${data.id}/parmission`, { role_access: data.role_access }, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _approve_merchant = async ({ id, value }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.patch(`/mertant-profile/${id}/approve`, { status: value }, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.patch(`/mertant-profile/${id}/approve`, { status: value }, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _change_merchant_status = async ({ id, value }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.patch(`/mertant-profile/${id}/activate`, { status: value }, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.patch(`/mertant-profile/${id}/activate`, { status: value }, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _update_merchant = async ({ data, id }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.patch(`/merchant-profile/${id}/`, data, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.patch(`/merchant-profile/${id}/`, data, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _get_merchant_user = async ({ id }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/mertant-profile/${id}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/mertant-profile/${id}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

export const _get_konnect_report = async ({ page, page_size }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/transfer/konnect_tranfer_request?page=${page}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/transfer/konnect_tranfer_request?page=${page}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _get_drivers = async ({ page, page_size }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/driver-profile?page=${page}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/driver-profile?page=${page}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

export const _get_drivers_request = async ({ page, page_size, search, delivery_date_id, from, to }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/delivery-drivers/all_request?page=${page}&per_page=${page_size}&from=${from}&to=${to}&search=${search}&delivery_date_id=${delivery_date_id}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/delivery-drivers/all_request?page=${page}&per_page=${page_size}&from=${from}&to=${to}&search=${search}&delivery_date_id=${delivery_date_id}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}
export const _get_delivery_drivers = async ({ search, delivery_date_id, from, to }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/delivery-drivers/approved?search=${search}&delivery_date_id=${delivery_date_id}&from=${from}&to=${to}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/delivery-drivers/approved?search=${search}&delivery_date_id=${delivery_date_id}&from=${from}&to=${to}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}
export const _approve_delivery_driver = async ({ id, comment, status }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.patch(`/delivery-drivers/approval/${id}`, { status, comment }, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.patch(`/delivery-drivers/approval/${id}`, { status, comment }, { headers: { "Authorization": "Bearer " + "" } })
    }
}
export const _get_outbound_pickup_by_driver = async ({ page, page_size, delivery_date_id, driver_id }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/outbound/items?page=${page}&per_page=${page_size}&delivery_date_id=${delivery_date_id}&driver_delivery_id=${driver_id}&lga=`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/outbound/items?page=${page}&per_page=${page_size}&delivery_date_id=${delivery_date_id}&driver_delivery_id=${driver_id}&lga=`, { headers: { "Authorization": "Bearer " + "" } })
    }
}
export const _get_pickup_by_driver = async ({ page, page_size, today_day, driver_id, search }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/outbound/driver/${driver_id}?page=${page}&per_page=${page_size}&search=${search}&today_day=${today_day}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/outbound/driver/${driver_id}?page=${page}&per_page=${page_size}&search=${search}&today_day=${today_day}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}
export const _get_pickup = async ({ page, page_size, from, to, search }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/inventory/pickup?from=${from}&to=${to}&page=${page}&per_page=${page_size}&search=${search}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/inventory/pickup?from=${from}&to=${to}&page=${page}&per_page=${page_size}&search=${search}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}
export const _get_van_types = async ({ page, page_size, from, to, search }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/van-type?from=${from}&to=${to}&page=${page}&per_page=${page_size}&search=${search}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/van-type?from=${from}&to=${to}&page=${page}&per_page=${page_size}&search=${search}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}
export const _add_van_type = async (data) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.post(`/van-type`, data, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.post(`/van-type`, data, { headers: { "Authorization": "Bearer " + "" } })
    }
}
export const _delete_van_type = async ({ id }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.delete(`/van-type/${id}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.delete(`/van-type/${id}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _get_available_drivers = async ({ page, page_size }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/driver-profile/available?page=${page}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/driver-profile/available?page=${page}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _check_allocation = async ({ id, driver_id, checked }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.patch(`/allocations/check/${id}`, { driver_id, checked }, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.patch(`/allocations/check/${id}`, { driver_id, checked }, { headers: { "Authorization": "Bearer " + "" } })
    }
}

export const _konnect_trans_approval = async ({ id, status }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.patch(`/transfer/konnect_tranfer_approval/${id}`, { status }, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.patch(`/transfer/konnect_tranfer_approval/${id}`, { status }, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _confirm_allocation = async ({ id, driver_id }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.patch(`/allocations/confirm/${id}`, { driver_id }, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.patch(`/allocations/confirm/${id}`, { driver_id }, { headers: { "Authorization": "Bearer " + "" } })
    }
}
const _uncheck_allocation = async ({ id, driver_id, checked }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.patch(`/allocations/uncheck/${id}`, { driver_id, checked }, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.patch(`/allocations/uncheck/${id}`, { driver_id, checked }, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _get_outbound_percel = async ({ date, driver, page, page_size }) => {
    const lga = ""
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/outbound/buyers?delivery_date_id=${date}&lga=${lga}&driver_id=${driver}&page=${page}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/outbound/buyers?delivery_date_id=${date}&lga=${lga}&driver_id=${driver}&page=${page}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _get_outbound_items = async ({ date, lga, driver, page, page_size }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/outbound/items?delivery_date_id=${date}&lga=${lga}&driver_id=${driver}&page=${page}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/outbound/items?delivery_date_id=${date}&lga=${lga}&driver_id=${driver}&page=${page}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}
const _get_outbound_matrix = async ({ date, driver, }) => {
    const lga = ''
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/outbound/metrix_analysis?delivery_date_id=${date}&lga=${lga}&driver_id=${driver}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/outbound/metrix_analysis?delivery_date_id=${date}&lga=${lga}&driver_id=${driver}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

export const _get_allocation_driver_matrix = async ({ date, lga, id }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/allocations/metrix_analysis/driver?today_day=${date}&lga=${lga}&driver_id=${id}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/allocations/metrix_analysis/driver?today_day=${date}&lga=${lga}&driver_id=${id}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}
const _get_allocation_matrix = async ({ date, lga, }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/allocations/metrix_analysis?today_day=${date}&lga=${lga}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/allocations/metrix_analysis?today_day=${date}&lga=${lga}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _get_inbound_matrix = async ({ date, status }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/inbound/metrix_analysis?delivery_date_id=${date}&status=${status}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/inbound/metrix_analysis?delivery_date_id=${date}&status=${status}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _get_instore_matrix = async () => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/instore/metrix_analysis`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/instore/metrix_analysis`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _get_admins = async ({ page, page_size, search, merchant_id, from, to }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/admin/user/admin?page=${page}&per_page=${page_size}&search=${search}&merchant_id=${merchant_id}&from=${from}&to=${to}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/admin/user/admin?page=${page}&per_page=${page_size}&search=${search}&merchant_id=${merchant_id}&from=${from}&to=${to}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _get_admin = async ({ id }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/admin-profile/${id}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/admin-profile/${id}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _get_driver = async ({ id }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/driver-profile/${id}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/driver-profile/${id}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _add_admin = async (data) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.post(`/admin/user`, data, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.post(`/admin/user`, data, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _add_driver = async (data) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.post(`/driver-profile`, data, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.post(`/driver-profile`, data, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _confirm_email = async ({ id }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/admin/user/${id}/confirm-email`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/admin/user/${id}/confirm-email`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _approve_admin = async ({ id, value }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.patch(`/admin-profile/${id}/approve`, { status: value }, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.patch(`/admin-profile/${id}/approve`, { status: value }, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _change_admin_status = async ({ id, value }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.patch(`/admin-profile/${id}/activate`, { status: value }, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.patch(`/admin-profile/${id}/activate`, { status: value }, { headers: { "Authorization": "Bearer " + "" } })
    }
}
const _approve_driver = async ({ id, value }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.patch(`/driver-profile/${id}/approve`, { status: value }, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.patch(`/driver-profile/${id}/approve`, { status: value }, { headers: { "Authorization": "Bearer " + "" } })
    }
}
const _activate_driver = async ({ id, value }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.patch(`/driver-profile/${id}/activate`, { status: value }, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.patch(`/driver-profile/${id}/activate`, { status: value }, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _update_admin = async ({ data, id }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.patch(`/admin-profile/${id}/`, data, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.patch(`/admin-profile/${id}/`, data, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _get_inbound_details = async ({ id }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/inbound/${id}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/inbound/${id}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}
const _get_outbound_details = async ({ id }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/outbound/${id}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/outbound/${id}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _get_instore_details = async ({ id }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/instore/${id}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/instore/${id}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _change_inbound_status = async ({ status, id }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.patch(`/inbound/${id}/change_status`, { status }, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.patch(`/inbound/${id}/change_status`, { status }, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _change_inbound_instore_status = async ({ id }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.patch(`/inbound/${id}/confirm_instore`, {}, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.patch(`/inbound/${id}/confirm_instore`, {}, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _update_inbound_data = async ({ id, data }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall.patch(`/inbound/${id}/supply_quantity`, data, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall.patch(`/inbound/${id}/supply_quantity`, data, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _get_inboundby_dateid = async ({ id, page, page_size, status }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/inbound?page=${page}&delivery_date_id=${id}&status=${status}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/inbound?page=${page}&delivery_date_id=${id}&status=${status}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}
export const _get_items_inbound = async ({ id, page, page_size }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/inbound/items?page=${page}&delivery_date_id=${id}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/inbound/items?page=${page}&delivery_date_id=${id}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}
export const _get_buyers_inbound = async ({ id, page, page_size }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/inbound/buyers?page=${page}&delivery_date_id=${id}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/inbound/buyers?page=${page}&delivery_date_id=${id}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}
export const _get_buyer_user_inbound = async ({ user_id, delivery_date_id }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/inbound/buyers/${user_id}?delivery_date_id=${delivery_date_id}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/inbound/buyers/${user_id}?delivery_date_id=${delivery_date_id}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _get_all_inbound = async ({ page, page_size, status }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/inbound?page=${page}&per_page=${page_size}&status${status}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/inbound?page=${page}&per_page=${page_size}&status${status}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _get_all_instore = async ({ page, page_size }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/instore?page=${page}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/instore?page=${page}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _get_outboundby_dateid = async ({ id, page, page_size }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/outbound/delivery_date/${id}?page=${page}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/outbound/delivery_date/${id}?page=${page}&per_page=${page_size}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

const _get_dateby_id = async ({ id }) => {
    const jwt_token = localStorage.konnect_admin_token
    if (jwt_token) {
        const token = Jwt_decrypt({ token: jwt_token })
        return axiosCall(`/delivery-date/${id}`, { headers: { "Authorization": "Bearer " + token } })
    } else {
        return axiosCall(`/delivery-date/${id}`, { headers: { "Authorization": "Bearer " + "" } })
    }
}

export { _add_admin, _add_city, _add_date, _add_delivery_fee, _add_driver, _add_location, _add_merchants_settings, _add_merchants_user, _add_state, _approve_admin, _approve_driver, _approve_merchant, _change_admin_status, _change_inbound_instore_status, _change_inbound_status, _change_merchant_status, _check_allocation, _confirm_allocation, _confirm_email, _date_id, _delete_city, _delete_date, _delete_delivery_fee, _delete_location, _delete_state, _edit_city_status, _edit_date, _edit_fee, _edit_location_status, _edit_state_status, _edit_wise_status, _fee_id, _get_admin, _get_admin_matrix, _get_admins, _get_all_cities, _get_all_inbound, _get_all_instore, _get_all_payments, _get_all_state, _get_all_wallet, _get_allocation_matrix, _get_allocation_orders, _get_available_drivers, _get_bills_report, _get_city, _get_dateby_id, _get_dates, _get_delivery_fees, _get_driver, _get_drivers, _get_each_order, _get_inbound_details, _get_inbound_matrix, _get_inboundby_dateid, _get_instore_details, _get_instore_matrix, _get_lga, _get_locations, _get_loyalty_report, _get_merchant_user, _get_merchants_settings, _get_merchants_users, _get_orderby_dateid, _get_orders, _get_outbound_details, _get_outbound_items, _get_outbound_matrix, _get_outbound_percel, _get_outboundby_dateid, _get_profile, _get_roles, _get_roles_byusertype, _get_shop_matrix, _get_state, _get_user_payments, _get_wallet_month, _get_wallet_report, _get_wise9ja, _get_wise9ja_month, _get_wise9ja_report, _uncheck_allocation, _update_admin, _update_inbound_data, _update_merchant, _update_permission, _wise9ja_plan }

